import deSites from '../JSON/StaticSitesAdresses/deSites.json';
import enSites from '../JSON/StaticSitesAdresses/enSites.json';
import deAtSites from '../JSON/StaticSitesAdresses/deAtSites.json';
import enAuSites from '../JSON/StaticSitesAdresses/enAuSites.json';
import enInSites from '../JSON/StaticSitesAdresses/enInSites.json';
import enSgSites from '../JSON/StaticSitesAdresses/enSgSites.json';
import enUkSites from '../JSON/StaticSitesAdresses/enUkSites.json';
import enUsSites from '../JSON/StaticSitesAdresses/enUsSites.json';
import esEsSites from '../JSON/StaticSitesAdresses/esEsSites.json';
import esSites from '../JSON/StaticSitesAdresses/esSites.json';
import esUsSites from '../JSON/StaticSitesAdresses/esUsSites.json';
import frBeSites from '../JSON/StaticSitesAdresses/frBeSites.json';
import frFrSites from '../JSON/StaticSitesAdresses/frFrSites.json';
import frSites from '../JSON/StaticSitesAdresses/frSites.json';
import itSites from '../JSON/StaticSitesAdresses/itSites.json';
import nlBeSites from '../JSON/StaticSitesAdresses/nlBeSites.json';
import nlSites from '../JSON/StaticSitesAdresses/nlSites.json';
import zhHansSites from '../JSON/StaticSitesAdresses/zhHansSites.json';
import * as language_code from '../constants/languageCode';

export enum StaticPages {
    Product = 'products',
    Contact = 'contact',
    Industries = 'industries',
    SecondHandMachines = 'secondHandMachines',
}

export const generateStaticUrl = (language: string, alias: string) => {
    switch (language) {
        case language_code.DE:
            return `/${language_code.DE}/${deSites[alias]}`;
        case language_code.DE_AT:
            return `/${language_code.DE_AT}/${deAtSites[alias]}`;
        case language_code.EN:
            return `/${language_code.EN}/${enSites[alias]}`;
        case language_code.EN_AU:
            return `/${language_code.EN_AU}/${enAuSites[alias]}`;
        case language_code.EN_IN:
            return `/${language_code.EN_IN}/${enInSites[alias]}`;
        case language_code.EN_SG:
            return `/${language_code.EN_SG}/${enSgSites[alias]}`;
        case language_code.EN_UK:
            return `/${language_code.EN_UK}/${enUkSites[alias]}`;
        case language_code.EN_US:
            return `/${language_code.EN_US}/${enUsSites[alias]}`;
        case language_code.ES:
            return `/${language_code.ES}/${esSites[alias]}`;
        case language_code.ES_ES:
            return `/${language_code.ES_ES}/${esEsSites[alias]}`;
        case language_code.ES_US:
            return `/${language_code.ES_US}/${esUsSites[alias]}`;
        case language_code.FR:
            return `/${language_code.FR}/${frSites[alias]}`;
        case language_code.FR_BE:
            return `/${language_code.FR_BE}/${frBeSites[alias]}`;
        case language_code.FR_FR:
            return `/${language_code.FR_FR}/${frFrSites[alias]}`;
        case language_code.IT:
            return `/${language_code.IT}/${itSites[alias]}`;
        case language_code.NL_NL:
            return `/${language_code.NL_NL}/${nlSites[alias]}`;
        case language_code.NL_BE:
            return `/${language_code.NL_BE}/${nlBeSites[alias]}`;
        case language_code.ZH_HANS:
            return `/${language_code.ZH_HANS}/${zhHansSites[alias]}`;
        default:
            return `/${language_code.DE}/${deSites[alias]}`;
    }
};
